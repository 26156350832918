import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Latest Release`}</h2>
    <p>{`This is the changelog information of latest version and if you have any ideas or feedback for improvements in the next version please tell us from the feedback section`}</p>
    <div style={{
      "backgroundColor": "rgb(244, 242, 252)",
      "color": "rgb(135, 91, 247)",
      "padding": "1rem",
      "borderBottom": "1px solid #875bf7",
      "padding": "1rem"
    }}>
  <p style={{
        "fontWeight": "700"
      }}>4 September 2024</p>
    </div>
    <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /></h3>
    <p><strong parentName="p">{`iOS (Legion-Prototype) - Release Version : 2.0.0`}</strong>{` `}<br /></p>
    <p><strong parentName="p">{`Bug Fix`}</strong>{`: Fixed the ThirdPartySSO Transition to correct Pages/Screen `}<br /></p>
    <p><strong parentName="p">{`Bug Fix`}</strong>{`: Fixed wording and back button for Login SSO `}<br /></p>
    <p><strong parentName="p">{`Update`}</strong>{`: Inserted the updated components: `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Checkbox`}</inlineCode>{`, `}<inlineCode parentName="p">{`Button`}</inlineCode>{`, `}<inlineCode parentName="p">{`TextField`}</inlineCode>{`, `}<inlineCode parentName="p">{`Divider`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Badge`}</inlineCode>{` `}<br /></p>
    <p><strong parentName="p">{`Bug Fix`}</strong>{`: Fixed the Home and Pattern Pages bug `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: Added New Patterns: Username, Telkom (NIK) as Primary Key, and Mobile Number `}<br /></p>
    <p><strong parentName="p">{`Update`}</strong>{`: Updated Login Patterns to align with the revised BaseLGNLogin Interface `}<br /></p>
    <p><strong parentName="p">{`New`}</strong>{`: Added Back button in Home View `}<br /></p>
    <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br /></h3>
    <ul>
      <li parentName="ul">
        <br />
      </li>
    </ul>
    <div className="div" />
    <h2>{`Previous Release`}</h2>
    <p>{`Find the changelog information on the previous version by clicking the section below`}</p>
    <Accordion mdxType="Accordion">
      <AccordionItem title="19 July 2024" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /><br /></h3>
        <p><strong parentName="p">{`iOS (Legion-iOS) - Release Version : 2.3.0`}</strong>{` `}<br /><br /></p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Update`}</strong>{`: Improve `}<inlineCode parentName="li">{`LGNTextField`}</inlineCode>{` appearance and open background properties.`}</li>
        </ul>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br /><br /></h3>
        <ul>
          <li parentName="ul"></li>
        </ul>
      </AccordionItem>
      <AccordionItem title="11 July 2024" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /><br /></h3>
        <p><strong parentName="p">{`iOS (Legion-iOS) - Release Version : 2.2.2`}</strong>{` `}<br /><br /></p>
        <ul>
          <li parentName="ul"><strong parentName="li">{`Fix`}</strong>{`: Replace the bugfix icon in `}<inlineCode parentName="li">{`LGNAlert`}</inlineCode>{`, Use a system icon instead of an image asset.`}</li>
        </ul>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br /><br /></h3>
        <ul>
          <li parentName="ul"></li>
        </ul>
      </AccordionItem>
      <AccordionItem title="21 June 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /><br /></h3>
        <p><strong parentName="p">{`iOS (Legion-iOS) - Release Version : 2.2.0`}</strong>{` `}<br /><br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Comments & readme on Switch component `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Support Autocapitalization on LGNTextField `}<br /><br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: missing textfield placeholder `}<br /><br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Fix broken textfield’s LGNContentModel inaccessible from all themes `}<br /><br /></p>
        <p><strong parentName="p">{`Bug Fix`}</strong>{`: Fix soft button showProgressView tint color, and open all properties of all buttons `}<br /><br /></p>
        <p><strong parentName="p">{`New`}</strong>{`: Add secure text support to LGNTextField `}<br /><br /></p>
      </AccordionItem>
      <AccordionItem title="4 April 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br /><br /></h3>
        <p><strong parentName="p">{`iOS (Legion-iOS) - Release Version : 2.1.0`}</strong>{` `}<br /><br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Fix broken `}<inlineCode parentName="p">{`Textfield`}</inlineCode>{`’s `}<inlineCode parentName="p">{`LGNContentModel`}</inlineCode>{` inaccessible from all themes `}<br /><br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Enable swipe-up to expand `}<inlineCode parentName="p">{`LGNSheetBottom`}</inlineCode>{` `}<br /><br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Open few parameters for modification on `}<inlineCode parentName="p">{`LGNInlineTextField`}</inlineCode>{`, `}<inlineCode parentName="p">{`LGNOutlineTextField`}</inlineCode>{`, `}<inlineCode parentName="p">{`LGNInlineTextView`}</inlineCode>{` and `}<inlineCode parentName="p">{`LGNOutlineTextView`}</inlineCode>{` `}<br /><br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Fix inaccessible fonts on Themes `}<br /><br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Adjust theme color token and font accessibility `}<br /><br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Fix color and typography for `}<inlineCode parentName="p">{`ThemeEazy`}</inlineCode>{` `}<br /><br /></p>
        <p><strong parentName="p">{`Fix`}</strong>{`: Add namespace to color assets `}<br /><br /></p>
        <p><strong parentName="p">{`Update`}</strong>{`: Create `}<inlineCode parentName="p">{`ThemeEazy`}</inlineCode>{` `}<br /><br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br /><br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 1.0.8`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{` : Add Alert component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Remove Variant primary, responsive, and state focussed Anchor Text component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Remove Variant state focussed Button component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add development status information & design guideline link update in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
      </AccordionItem>
      <AccordionItem title="7 February 2023" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`iOS (Legion-iOS) - Release Version : 2.0.0`}</strong>{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Added new theme support `}<inlineCode parentName="p">{`AGR`}</inlineCode>{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Added new theme support `}<inlineCode parentName="p">{`MyTens`}</inlineCode>{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : New Component `}<inlineCode parentName="p">{`Alert`}</inlineCode>{` `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 1.0.8`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`New`}</strong>{` : Add Alert component in Legion Project - UI Library - Mobile UI Component `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Remove Variant primary, responsive, and state focussed Anchor Text component in Legion Project - UI Library - Mobile UI Component `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Remove Variant state focussed Button component in Legion Project - UI Library - Mobile UI Component `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add development status information & design guideline link update in Legion Project - UI Library - Mobile UI Component `}<br /></p>
      </AccordionItem>
      <AccordionItem title="15 December 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`iOS (Legion-iOS) - Release Version : 1.0.0`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{` Component `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Bottom Sheet`}</inlineCode>{` Component`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Button`}</inlineCode>{` Component`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Radio Button`}</inlineCode>{` Component`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Snackbar`}</inlineCode>{` Component`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`Switch`}</inlineCode>{` Component`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`TextField`}</inlineCode>{` Component`}<br />{` `}<br />{`
`}<strong parentName="p">{`Update`}</strong>{` : Add new `}<inlineCode parentName="p">{`TextArea`}</inlineCode>{` Component`}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 1.0.5`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Change default and small size variant Switch component in Legion Project - UI Library - Mobile UI Component`}</p>
      </AccordionItem>
      <AccordionItem title="3 November 2022" mdxType="AccordionItem">
        <h3><strong parentName="h3">{`Development Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`iOS (Legion-iOS) - Release Version : 0.1.0`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update :`}</strong>{` Add Legion Fill Button `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update :`}</strong>{` Add Legion Outline Text Field `}<br />{` `}<br />{`
`}<strong parentName="p">{`Update :`}</strong>{` Add Legion Inline Text Fiel `}<br />{` `}<br /></p>
        <h3><strong parentName="h3">{`Design Library Updates`}</strong>{` `}<br />{` `}<br /></h3>
        <p><strong parentName="p">{`Mobile Pattern and UI Kit - Release Version: 1.0.4`}</strong>{` `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Change colors `}<inlineCode parentName="p">{`Tab Bar`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component `}<br />{` `}<br /></p>
        <p><strong parentName="p">{`Update`}</strong>{` : Remove line and change to text underline `}<inlineCode parentName="p">{`Anchor Text`}</inlineCode>{` component in Legion Project - UI Library - Mobile UI Component`}</p>
      </AccordionItem>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      